// extracted by mini-css-extract-plugin
export var noriakiLink = "styles-module--noriakiLink--5Ojcm";
export var root = "styles-module--root--W51kx";
export var topTitle = "styles-module--topTitle--Q3Rmn";
export var header = "styles-module--header--5TiXo";
export var title = "styles-module--title--UadQ4";
export var papa = "styles-module--papa--rCZ6e";
export var snsContent = "styles-module--snsContent--+owim";
export var snsContentText = "styles-module--snsContentText--f4Yqm";
export var topNews = "styles-module--topNews--j8Ljv";
export var topImage = "styles-module--topImage--fKOHB";
export var test = "styles-module--test--GLsTr";