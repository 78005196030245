import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as styles from '../styles/top/styles.module.css';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';

const Top = () => {
  library.add(fab)

  return (
    <div>
      {/*<Header/>*/}
      <main className={styles.root}>
        <div className={styles.main}>
          <SnsContent/>
          {/*<div className="grid grid-cols-1">*/}
          {/*  <img className="object-fill h-full w-full" src={TopImage}  />*/}
          {/*</div>*/}
        </div>
      </main>
    </div>
  )
}

export const Header = () => {
  return (
    <header>
      <nav className="flex items-center justify-between flex-wrap p-4">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
            <span className="font-semibold text-3xl tracking-tight">k<span
              className={styles.topTitle}>4</span>sen</span>
        </div>
        <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="text-sm lg:flex-grow mt-2">
            <a href="#responsive-header"
               className="mt-4 lg:inline-block lg:mt-0 mr-4">
              News
            </a>
            <a href="#responsive-header"
               className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">
              Amazon
            </a>
            <a href="#responsive-header"
               className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">
              Clips
            </a>
            <a href="#responsive-header"
               className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">
              Company
            </a>
            <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 hover:text-white">
              Noriaki
            </a>
          </div>
          <div className={styles.noriakiLink}>
            <a href="#"
               className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent mt-4 lg:mt-0">
              <span>D</span><span>O</span> <span>N</span><span>O</span><span>T</span>
              <span>J</span><span>O</span><span>I</span><span>N</span>
            </a>
          </div>
        </div>
      </nav>
    </header>
  )
}

export const SnsContent = () => {
  return (
    <div className={styles.snsContent}>
      <div className="grid lg:grid-cols-5  sm:grid-cols-2 gap-x-5">
        <a href="https://twitter.com/k4sen" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'twitter']} size='5x'/>
          <div className="text-xs mt-4">Twitter</div>
        </a>
        <a href="https://www.youtube.com/user/zzxk4sen" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'youtube']} size='5x'/>
          <div className="text-xs mt-4">YouTube</div>
        </a>
        <a href="https://www.twitch.tv/k4sen" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'twitch']} size='5x'/>
          <div className="text-xs mt-4"><span className={styles.snsContentText}>Twitch</span></div>
        </a>
        <a href="https://www.amazon.co.jp/?tag=k4sendenei0c-22" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'amazon']} size='5x'/>
          <div className="text-xs mt-4">Amazon</div>
        </a>
        <a href="https://discord.gg/57VqX4DCD7" target="_blank" rel="noopener noreferrer"
           className={styles.discord}>
          <FontAwesomeIcon icon={['fab', 'discord']} size='5x'/>
          <div className="text-xs mt-4">Discord</div>
        </a>
      </div>
    </div>
  )
}

export default Top;
